import React from 'react';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../store/selectors';
import { UserRole } from '../../api/common/types';

export const HelloPage = ({ helloText }: { helloText: string }) => {
	const userRole = useSelector(getUserRole);
	const tgLink = process.env.REACT_APP_LINK_TO_TELEGRAM_CHAT;

	return (
		<div className='mt-5 d-flex justify-content-center flex-column gap-4'>
			<h2 className='text-center' style={{ whiteSpace: 'pre-wrap' }}>
				{helloText}
			</h2>
			<div className='d-flex justify-content-center'>
				<img width='300' src='shared/images/sb_logo.png' alt='' />
			</div>
			{(userRole === UserRole.NEWCOMER || userRole === UserRole.USER) && (
				<h3 className='text-center border border-3 border-info p-2'>
					<a href={tgLink}>Чат СБ в Телеграмі</a>
				</h3>
			)}
		</div>
	);
};
